import * as React from "react"
import styled from "styled-components";
import { navigate } from "gatsby"

import Main from "../../components/atoms/Main/Main";

import MainNav from "../../components/molecules/MainNav/MainNav";
import Unity, {UnityContext} from "react-unity-webgl";
import {useEffect} from "react";

const unityContext = new UnityContext({
  loaderUrl: "/paare-finden4/Build/WebGL.loader.js",
  dataUrl: "/paare-finden4/Build/WebGL.data",
  frameworkUrl: "/paare-finden4/Build/WebGL.framework.js",
  codeUrl: "/paare-finden4/Build/WebGL.wasm",
  streamingAssetsUrl: "StreamingAssets",
  companyName: "DefaultCompany",
  productName: "Memory",
  productVersion: "1.0",
});

const GameContainer = styled.div`
  height: calc(100% - 80px);
  width: 100%;
  canvas {
    width: 100% !important;
    height: 100% !important;
    // aspect-ratio: auto 428 / 670;
  }
`;

// markup
const IndexPage = () => {

  useEffect(function () {
    unityContext.on("GameOver", function (points) {
      // alert(`Yeah, ${points}`);
      navigate("/app/paare_end");
    });
  }, []);

  return (
    <Main footerContent={(
      <MainNav active="spiele"/>
    )}>
      <GameContainer>
        <Unity unityContext={unityContext} width="428" height="670" />
      </GameContainer>
    </Main>
  )
}

export default IndexPage
